
.__footer {
    background: #fff;
    width: 100%;
    color: #444;
    padding-top: 30px;
    box-shadow: -1px -1px 10px #0003;
    position: relative;
    z-index: 11;

    .__foot_links {
        display: flex;
        flex-direction: column;

        a {
            color: #444;
            font-weight: 300;
            text-decoration: none;
            margin-bottom: 20px;
            transition: 0.4s;

            @media screen and (max-width: 555px) {
                margin-bottom: 10px;
            }

            &:hover {
                color: #ccc;
            }
        }
    }

    .__copywright {
        padding: 15px 0;
        background: #fff;
        border-top: 1px solid #ddd;
    }

    .__logo {
        height: 40px;
        object-fit: contain;
        border-radius: 8px;
        cursor: pointer;
        transition: 0.4s;

        @media screen and (max-width: 555px) {
            height: 25px;
        }
    }
    .__ft_abt_de{
        font-weight: 300;
        font-size: 13px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 5px;
    }

}
.arabic {
    .__ars{
        direction: rtl;
    } 
}

.__social {
    display: flex;
    align-items: center;
    justify-content: end;
    grid-gap: 16px;

    a {
        font-size: 23px;
        cursor: pointer;
        text-decoration: none;
        line-height: 1;
        display: flex;
        color: #555 !important;
        transition: 0.4s;

        &:hover {
            color: #4095FF !important;
        }
    }

    .__nav_subscribe{
        border: 1px solid;
        font-size: 18px;
        padding: 4px 20px;
        border-radius: 40px;    
    }
}

.__sub_s {
    display: grid;
    align-items: center;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-top: 15px;

    @media screen and (max-width: 555px) {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    a {
        text-decoration: none;
    }

    button{
        @media screen and (max-width: 555px) {
         min-width: 94vw;
        }
    }
}

.__lgo_h {
    width: 70px;
    border-radius: 10px;

    @media screen and (max-width: 555px) {
        width: 40px;
    }
}

.__lgo_text {
    height: 20px;

    @media screen and (max-width: 555px) {
        height: 12px;
    }
}


.arabic {
    .__footer {
        .__foot_links a {
            font-size: 20px;

        }
        .__ft_abt_de{
            font-size: 19px;
        }
    }
}