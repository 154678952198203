// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lateef&display=swap');

body {
    margin: 0;
    width: 100%;
    height: 100%;
    position: relative;
    font-family: 'Nunito Sans', sans-serif;
}

.arabic {
    font-family: 'Lateef', sans-serif !important;
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px
}

::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: #edf2f7
}

::-webkit-scrollbar-thumb {
    background: #989fa7;
    border-radius: 100vh;
    border: 3px solid #edf2f7
}

::-webkit-scrollbar-thumb:hover {
    background: #484d52
}

.content-wrapper {
    min-height: 85vh;
    padding-bottom: 30px;
}


.__common_grid_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 30px;
    margin: 30px 0px 15px;
}

.__common-head {
    font-size: 28px;
    font-weight: 600;
    line-height: 29px;
    margin-bottom: 20px;
}

.__videos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 30px;
}

.__photos-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    grid-gap: 30px;

    img {
        height: 170px;
        object-fit: cover;
        width: 100%;
        box-shadow: 0px 0px 10px #0005;
        border: 2px solid #fff;
        border-radius: 10px;
        cursor: pointer;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    border-radius: 10px;
    overflow: hidden;
    background: #000;
    box-shadow: 0px 0px 10px #0005;
    border: 2px solid #fff;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.__banner_set {
    min-height: 600px;
}

.imageLoader {
    background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
    animation: 1.5s shine linear infinite;
    background-size: 200% 100%;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}


.arabic {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    a,
    button {
        direction: rtl;
    }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button {
    margin: 0;
}

.__padding_top {
    padding-top: 80px;
}

.arabic {
    .__right_align {
        direction: rtl;
    }
    .__common_para {
        font-family: 'Lateef', sans-serif !important;
        font-size: 28px;
        p{
            font-size: 22px;
            font-family: 'Lateef', sans-serif !important;
        }
    }
    .__comment_sec {
        .__comnt_icon_set {
            a {
                font-size: 22px;

                p {
                    font-size: 22px;
                }
            }

        }
    }
}

.__common_para {
    font-size: 24px;
    line-height: 1.8;
    font-family: 'Nunito Sans', sans-serif;
}





.__innerTopPad {
    padding-top: 50px;
}



.__top_banner_img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 70vh;
    z-index: -1;
    object-fit: cover;
    pointer-events: none;
}

.__top_gradient {
    background: linear-gradient(181deg, #0d0d0d, transparent);
    height: 70vh;
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: -1;
}

.__box {
    padding: 40px;
    background: #ffffffd4;
    border-radius: 40px;
    box-shadow: 0px 0px 10px #0002;
    position: relative;
    margin-bottom: 40px;
    padding-bottom: 25px;
}

.__banner_head {
    font-size: 3.2rem;
    color: #fff;
    margin-bottom: 10px;
    margin-top: 40px;
}

.__banner_sub_head {
    font-size: 24px;
    margin-bottom: 20px;
    color: #fff;
    margin-top: 10px;
    font-weight: 400;
}

.__icon_set {
    display: flex;
    grid-gap: 10px;
    position: absolute;
    top: -30px;
    right: 40px;

    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px !important;
        height: 50px;
        color: #4095FF;
        box-shadow: 0px 0px 10px #00000054;

        svg {
            font-size: 21px;
        }
    }
}

.__comment_sec {
    padding-top: 20px;
    border-top: 1px solid #ddd;
    margin-top: 20px;

    .__comnt_icon_set {
        display: flex;
        grid-gap: 30px;

        a {
            display: flex;
            align-items: center;
            grid-gap: 11px;
            font-size: 15px;
            cursor: pointer;


            p {
                font-size: 15px;
                color: #777;
                line-height: 1;
                font-weight: 500;
            }
        }

        svg {
            width: 18px;
            height: 18px;
            font-size: 18px;
        }
    }
}






.root .text-display {
    font-size: 16px;
    line-height: 18px;
    overflow: hidden;
    transition: height 0.5s ease-in-out;
}

.root .btn-toggle {
    margin-top: 4px;
}

.root .text-display.collapsed {
    animation: mask-collapsing 0.5s;
    mask-image: linear-gradient(black 50%, transparent);
}

.root .text-display.expanded {
    animation: mask-expanding 0.5s;
    mask-image: linear-gradient(black 100%, transparent);
}

@keyframes mask-collapsing {
    from {
        mask-image: linear-gradient(black 100%, transparent);
    }

    to {
        mask-image: linear-gradient(black 50%, transparent);
    }
}

@keyframes mask-expanding {
    from {
        mask-image: linear-gradient(black 50%, transparent);
    }

    to {
        mask-image: linear-gradient(black 100%, transparent);
    }
}

.__buton_showmore {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.__btn-toggle {
    background: none;
    border: 0;
    border-top: 1px solid #e91e633d;
    width: 100px;
}


.__add_comment_wrap {
    margin: 10px 0;

    .__comment_adding {
        display: flex;
        justify-content: space-between;
        margin-top: 12px;
        grid-gap: 15px;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
            border-radius: 60px;
        }

        div {
            width: 100%;
            text-align: center;
        }
    }
}


.__comment_wrap {
    display: flex;
    margin-bottom: 25px;

    img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 60px;
        background: #3c3c3c;
    }

    h1 {
        font-size: 18px !important;
        margin-bottom: 5px;

        span {
            font-size: 13px;
            margin: 0 10px;
            color: #aaa;
            font-weight: 400;
        }
    }

    p {
        font-size: 14px !important;
        color: #666 !important;
        margin-top: 5px !important;
        margin-bottom: 0 !important;
    }

}

.__com-ed-wrap {
    display: flex;
    justify-content: space-between;
    grid-gap: 30px;

    .__com_actions {
        display: flex;
        align-items: start;
        grid-gap: 5px;

        button {
            border: 0;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
            width: 30px;
            background: #586e30;
            color: #ffffff;
        }
    }
}

.__common_model_content{
    button{
        margin: 0 !important;
    }
}

.____common_model_class_head{
    font-size: 21px;
    margin-bottom: 15px;
}


.__otp_content {
    input {
        margin: 0 10px;
        border: 1px solid rgba(147, 159, 50, 0.45);
        outline: none;
        height: 48px;
        font-size: 1.5rem;
        padding: 0px 10px;
        border-radius: 25px;
        color: rgb(110, 110, 110);
        transition: all 0.1s ease 0s;
        width: 48px !important;
    }
}

.__poems_list{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    grid-gap: 30px;
    margin: 30px 0px 15px;
}

.__poet_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 30px;
    margin: 30px 0px 15px;
}