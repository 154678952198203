.__sub_complete_img_sec {
    align-items: center;
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 0;
    max-width: 451px;
    margin: 0 auto;
}

.__sub_complete_sec {
     padding: 85px 0;
    position: relative;
    text-align: center;
}

.__sub_complete_head{
    color: #333539;
    font-size: 48px;
    font-weight: 700;
    line-height: 64px;
    margin: 0 auto 20px;
}

.__sub_complete_descr{
    color: #474f5f;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin: 0 auto;
    max-width: 600px;
}

.__sub_complete_full{
    background: linear-gradient(245.36deg, rgba(255, 41, 195, 0) 81.21%, rgba(255, 41, 195, .07) 95.29%), linear-gradient(263.22deg, rgba(23, 68, 255, .07) 5.82%, rgba(23, 74, 255, 0) 29.26%), #fff;
    height: 82vh;
    overflow: hidden;
}

.___thanks{
    .__banner_commn{
        height: 8vh;
    }
}