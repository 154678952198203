.__tabs_outline {
    font-family: inherit;
    margin-top: 70px;

    @media screen and (max-width: 666px) {
        display: flex;
        flex-direction: column;
    }

    .ant-tabs-tab {
        width: 270px;
        font-size: 18px;
        background: transparent;
        border-radius: 35px;
        margin: 0 !important;

        &:hover {
            color: #836a3c;
        }
    }

    .ant-tabs-tab-active {
        background: #4095FF !important;

    }

    .ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff !important;
    }

    .ant-tabs-ink-bar {
        background: transparent !important;
    }

    .ant-tabs-content-holder {
        border: none;
    }
}

.profile {
    width: 300px;
    margin: 0 auto;
    text-align: center;

    .prfImg {
        position: relative;
        width: 100px;
        margin: 0 auto;

        img {
            width: 100px;
            height: 100px;
            object-fit: cover;
            border: 1px solid #0002;
            border-radius: 100px;
        }

        .savePic {
            width: 30px;
            background: #ffffff;
            text-align: center;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            text-decoration: none;
            border-radius: 25px;
            position: absolute;
            bottom: 0;
            right: 0;
            z-index: 11;
            color: #4095FF;
            box-shadow: 0px 0px 10px #0003;
            font-size: 24px;
        }
    }

    .profCont {
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 20px;
            margin: 0;
        }

        a {
            font-size: 16px;
            padding: 11px 0;
            cursor: pointer;
            width: 300px;
            display: flex;
            align-items: center;
            text-decoration: none;

            &:hover {
                color: #4095FF;
                transition: 0.4s;
            }
        }
    }
}

.__fav_grid_list {
    grid-gap: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
    margin: 0 20px;
}

.__single_st {
    position: relative;

    .__del-bt {
        position: absolute;
        top: 0px;
        right: 0px;
        background: #ff4400;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 30px;
        border-radius: 30px 0 30px 30px;
        color: #fff;
    }
}

.___subscriptioName {
    background: #c70000;
    border: 0;
    padding: 4px 20px;
    border-radius: 30px;
    color: #fff;
    margin-bottom: 10px;
}