.__sub_p_Block {
    padding: 50px 30px;
    padding-bottom: 40px;
    border: 2px solid #eee;
    border-radius: 25px;
    position: relative;
    min-height: 320px;

    h1 {
        font-size: 30px;
        margin: 0;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 40px;
        font-weight: bold;
    }

    .recommend {
        padding: 3px 7px;
        background-color: #4095FF;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 1px;
        padding-top: 4px;

    }
}