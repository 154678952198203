.__subBlock {
    padding: 50px 30px;
    padding-bottom: 40px;
    border: 2px solid #eee;
    border-radius: 25px;
    position: relative;

    h1 {
        font-size: 30px;
        margin: 0;
        margin-bottom: 5px;
    }

    h2 {
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 30px;
    }

    .recommend {
        padding: 3px 7px;
        background-color: #4095FF;
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 1;
        border-radius: 10px;
        color: #fff;
        font-weight: bold;
        font-size: 13px;
        letter-spacing: 1px;
        padding-top: 4px;

    }
    p{
        display: flex;
        align-items: center;
    }
}

.arabic{
    .__subBlock{
         p{
        font-size: 20px;
    }
    button{
        font-size: 22px;
    }
}
    .__disco{
        input{
            font-size: 19px;
        }
        label{
            font-size: 19px;
        }
        button{
            font-size: 22px;
        }
    }
}
