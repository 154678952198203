.___single {
    border-radius: 10px;
    box-shadow: 0px 0px 10px #dee2e6;
    margin: 10px;
    display: flex;
    cursor: pointer;
    transition: 0.4s;
    position: relative;

    &:hover {
        box-shadow: 0px 0px 10px #0003;
    }

    .__premium {
        box-shadow: 0px 0px 10px #0002;
        border-radius: 10px;
        background: #e91e63;
        color: #fff;
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 6px 10px;
        font-size: 13px;
        line-height: 1;
        padding-top: 7px;
    }
}


.___img {
    flex: 1;
    background-image: url('./../../../assets/images/loading.gif');
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    width: 170px;
    overflow: hidden;
    border-radius: 10px;
    height: 238px;
    img {
        width: 100%;
        object-fit: cover;
        background: #0002;
        height: 100%;
        object-fit: cover;
    }
}

.___content {
    padding: 15px;
    position: relative;
    width: calc(100% - 170px);

    h1 {
        font-size: 24px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        flex: 1 1;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
    }

    button {
        margin-top: 5px;
        padding: 4px 20px;
        border-radius: 4px;
        background-color: #4095FF;
        color: #fff;
        margin: 10px 0;
        border: none;
        transition: 0.4s;

        &:hover {
            box-shadow: 0px 0px 10px #0003;
        }
    }

    p {
        font-size: 15px;
        font-weight: 300;
    }
}

.arabic {
    .___content {
        h1 {
            font-size: 28px;
        }

        p {
            font-size: 23px;
        }
    }

    .___single {
        .__premium {
            font-size: 18px;
        }
    }
}