header {
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 11;
}

.header_black {
    box-shadow: 0px 0px 10px #0002;
    background-color: #fff !important;
    color: #000 !important;

    .__menu {
        a {
            color: #000 !important;
            transition: 0.4s;
        }
    }
    
    .__dr_menu {
        h2 {
            color: #000;
        }
    }
    .__lng {
        color: #000 !important;
    }
    .__logo {
        filter: none !important;
    }
    .__dr_menu button {
        color: #000 !important;
    }

    .__profile_sec {

        .__prof-btn {
            color: #000 !important;
        }
    }
}
.arabic {
    .__header {
        .__menu {
            a {
                font-size: 25px;
            }
        }
    }
}

.__header {
    height: 80px;
    padding: 0 15px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    transition: 0.4s;
    background: #ffffff30;
    color: #fff;

    .__logo {
        height: 40px;
        object-fit: contain;
        border-radius: 8px;
        cursor: pointer;
        filter: invert(1);
        transition: 0.4s;

        @media screen and (max-width: 555px) {
            height: 25px;
        }
    }

    .__logo_text {
        width: 330px;
        cursor: pointer;

        @media screen and (max-width: 555px) {
            width: 165px;
        }
    }

    .__menu {
        flex: 8;
        text-align: center;

        @media screen and (max-width: 999px) {
            display: none;
        }

        a {
            font-size: 15px;
            font-weight: 300;
            margin: 0 20px;
            cursor: pointer;
            text-decoration: none;
            transition: 0.4s;
            color: #fff;

            &:hover {
                color: #bfb7b7;
            }
        }

        .active {
            border-bottom: 2px solid #4095FF;
            padding-bottom: 10px;
        }
    }


    .__dr_menu {
        display: flex;
        align-items: center;
        flex: 2;
        grid-gap: 5px;

        button {
            border: none;
            background: none;
            outline: none;
            color: #fff;
        }

        h2 {
            font-size: 26px;
            font-weight: 400;
            margin: 0;
        }
    }

    .__profile_sec {
        display: flex;
        justify-content: end;
        align-items: center;
        grid-gap: 10px;
        font-size: 20px;
        flex: 2;
        line-height: 1;

        .__prof-btn {
            text-transform: capitalize;
            padding: 2px;
            border: 1px solid;
            border-radius: 100px;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            line-height: 1;
            color: #fff;
        }

        .__lng {
            font-size: 16px;
            text-decoration: none;
            cursor: pointer;
            color: #fff;
        }
    }
}

.__drawer_menu {
    display: flex;
    flex-direction: column;
    padding: 20px 10px;
    color: #212121;
    position: relative;

    a {
        margin-bottom: 20px;
        font-size: 20px;
        cursor: pointer;
        text-decoration: none;
        width: max-content;
        display: flex;
        align-items: center;
        color: #212121;

        svg {
            margin: 0 10px;
        }

        &:hover {
            color: #ccc;
        }
    }

    .active {
        text-decoration: none;
        color: #4095FF;
    }

    .__social_icons {
        color: #bfb7b7;
        text-align: center;
        width: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: -24px;
        border-top: 1px solid #cccccc47;
        padding-top: 10px;

        h2 {
            font-size: 17px;
            margin-bottom: 15px;
        }

        h5 {
            font-size: 13px;
            margin: 0;
        }

        div {
            display: flex;
            grid-gap: 25px;

            a {
                &:hover {
                    color: #4095FF !important;
                }
            }
        }
    }
}

.lang_btn {
    background: none;
    font-size: 14px;
    border: none;
}

.arabic {
    .__drawer_menu {
        direction: rtl;
    }
}

.__divider {
    width: 100%;
    height: 1px;
    background-color: #cccccc47;
    margin-bottom: 15px;
}

.__drw_inr {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}


.__search_box {
    padding: 20px;
    .__input_sec {
        display: flex;
        align-items: self-start;
        gap: 10px;
        margin-bottom: 20px;
        div{
            width: 100%;
        }

        input {
            min-width: 300px;
            @media (max-width: 456px) {
                min-width: 200px;
            }
        }

        button {
            height: 48px;
            min-width: 48px;
            padding: 0;

            span {
                margin: 0 !important;
            }
        }

    }

    .__filter_sec {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        @media (max-width: 456px) {
            grid-template-columns: 1fr;
        }

        .__single{
            h5{
                margin-bottom: 10px;
                font-weight: bold;
            }
            .ant-radio-group{
                margin-left: 10px;
            }
        }
    }
}