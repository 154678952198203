.arabic {
    .__arb{
        direction: rtl;
    }
    .__icon{
        left: 16px !important;
        right: auto !important;
    }
}

