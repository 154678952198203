// .___single__poet {
//     // border-radius: 10px;
//     // box-shadow: 0px 0px 10px #00000029;
//     margin: 10px;
//     display: flex;
//     flex-direction: column;
//     overflow: hidden;
//     cursor: pointer;


//     .___img_poet {
//         flex: 1;
//         background-image: url('./../../../assets/images/loading.gif');
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         background-position: center;
//         background-repeat: no-repeat;
//         width: 100%;
//         overflow: hidden;

//         img {
//             width: 100%;
//             object-fit: cover;
//             background: #0002;
//             height: 200px;
//             object-fit: cover;
//             transform: scale(1.1);
//         }
//     }

// }

// .___content__poet {
//     padding: 10px;
//     position: relative;
//     text-align: center;

//     h1 {
//         font-size: 22px;
//         -webkit-line-clamp: 1;
//         -webkit-box-orient: vertical;
//         display: -webkit-box;
//         flex: 1 1;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         // margin-bottom: 5px;
//     }

//     button {
//         margin-top: 5px;
//         padding: 4px 10px;
//         border-radius: 4px;
//         background-color: #fff;
//         border: 1px solid #4095FF;
//         transition: 0.4s;
//         // text-transform: uppercase;
//         width: 100%;

//         &:hover {
//             box-shadow: 0px 0px 10px #0003;
//         }
//     }

//     p {
//         font-size: 15px;
//         font-weight: 300;
//         margin-bottom: 5px;

//         b {
//             font-weight: 500;
//         }
//     }
// }

.grid-academy__item {
    cursor: pointer;

    .card-academy,
    .card-academy__figure {
        position: relative;
    }

    .card-academy {
        --card-pad-x: clamp(20px, 3vw, 30px);
        --card-pad-y: clamp(10px, 3vw, 20px);
        --card-border: 1px solid #e9e9e9;
        --card-bg: #fff;
        background: var(--card-bg);
        border-radius: 1rem;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .card-academy__media {
        border-radius: 20px;
        padding: 10px;
        aspect-ratio: 4/3;
        display: block;
        object-fit: cover;
        object-position: center;
        transition: all .3s;
        width: 100%;
        background-image: url('./../../../assets/images/loading.gif');
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
    }

    .card-academy__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
    }

    .card-academy__row {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;
        padding: clamp(10px, 2vw, 20px) clamp(20px, 2vw, 20px);
        white-space: pre-wrap;
        padding-top: 5px;

        a {
            text-decoration: none;
            color: #063d8f;
        }

        .card-academy__desc,
        .card-academy__title {
            -webkit-box-orient: vertical;
            display: -webkit-box;
            flex: 1 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .card-academy__title {
            -webkit-line-clamp: 2;
            font-size: 18px;
            font-size: clamp(18px, 1vw, 24px);
            line-height: 120%;
        }

        .card-academy__desc {
            -webkit-line-clamp: 3;
            line-height: 140%;
            text-align: justify;
        }

        .__anchor {
            cursor: pointer;
            font-weight: 700;
            margin-top: 10px;
        }
    }



}

.arabic {
    .card-academy__title {
        font-size: 27px !important;
    }

    .card-academy__desc {
        font-size: 18px;
    }
}