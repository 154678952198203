

.__search_sec {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    input {
        padding: 13px 20px;
        background: #f2f2f2;
        color: #000;
        border-radius: 50px;
        font-size: 20px;
        outline: none;
        height: 55px;
        border: none;
        transition: 0.4s;
        width: calc(100% - 55px);
    }

    button {
        padding: 15px;
        outline: none;
        border: none;
        height: 55px;
        width: 55px;
        border-radius: 55px;
        background: #4095FF;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
    }
}

.__pagination {
    display: flex;
    button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #bdbdbd;
    padding: 7px 30px;
    border-radius: 20px;
    color: #fff;
    font-weight: bold;
}}