.__letters_list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-gap: 30px;
    margin: 30px 0px 15px;

    .__single_letter {
        height: 100px;
        background: #fff;
        border-radius: 20px;
        box-shadow: 0px 0px 100px #0002;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transition: 0.4s;
        &:hover {
            box-shadow: 0px 0px 10px #0003;
        }

        h1 {
            font-size: 30px;
            margin: 0;
        }

        h3 {
            font-size: 18px;
            color: #777;
        }
    }
}