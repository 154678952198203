.__banner_commn {
    width: 100%;
    background-image: url('./../../../assets/images/background.png');
    height: 40vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    color: #fff;

    h1 {
        font-size: 3rem;
        font-weight: 600;
        line-height: 29px;
        margin-bottom: 00px;
        position: absolute;
        color: #fff;
    }

    p {
        font-size: 1.7rem;
        font-weight: 600;
        line-height: 29px;
        margin-bottom: 0px;
        position: absolute;
        color: #fff;
        margin-top: 130px;
    }
}


._bnr__top_gradient {
    background: linear-gradient(181deg, #0d0d0d, transparent);
    left: 0;
    top: 0;
    width: 100%;
    position: absolute;
    height: 100%;
}