.__poet-wrapper {
    .__img_sec {
        position: relative;
        top: -14vh;

        img {
            width: 100%;
        }
    }

    .__content_sec {
        position: relative;
        top: -55px;

        h2 {
            color: #fff;
            font-size: 40px;
            margin-bottom: 18px;

            @media screen and (max-width: 768px) {
                color: #000;
            }
        }

        @media screen and (max-width: 768px) {
            top: -100px;
        }
    }
}

.arabic {
    .__poet-wrapper {

        .__content_sec {

            p {
                font-size: 25px !important;
            }
        }
    }
}