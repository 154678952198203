
.__header_box_sec {
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;

    h2 {
        font-size: 20px;
    }

    button {
        background-color: #fff;
        border: 2px solid #b2adad;
        border-radius: 10px;
        padding: 4px 20px;
        font-size: 14px;
        color: #555;
        transition: 0.4s;
        display: flex;
        align-items: center;

        &:hover {
            box-shadow: 0px 0px 10px #0003;
        }
    }
}

.__slid {

    .slick-prev,
    .slick-next {
        display: none !important;
    }
}

